import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            if (to.meta?.pageType === 'Product Page' && from.meta?.pageType === 'Product Page') {
              return
            }

            return {
                top: 0
            };
        }
    }
};